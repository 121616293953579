<template>
  <div id="InsureView" style="height: 100%">
    <div style="height: 28px"></div>
    <van-overlay :show="loading" style="text-align: center">
      <van-loading type="spinner">加载中...</van-loading>
    </van-overlay>

    <van-form validate-first @submit="insure" >
      <demo-block title="投保信息" v-if="product.companyCode == '1040'">
        <van-field name="radio" label="投保时间点" v-if="isShowInsuredTimePoint">
          <template #input>
            <van-radio-group v-model="insureFormData.insuredTimePoint" direction="horizontal">
              <van-radio name="1">科目一前</van-radio>
              <van-radio name="2">科目二前</van-radio>
            </van-radio-group>
          </template>
        </van-field>
      </demo-block>

      <demo-block title="" v-if="isNeedDrivingSchool">

        <van-field
            readonly
            clickable
            name="picker"
            :value="schoolName"
            label="驾校信息"
            placeholder="点击选择驾校信息"
            @click="showPicker = true"
        />
        <van-popup v-model="showPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="drivingSchool"
              value-key="schoolName"
              @confirm="onConfirm"
              @cancel="showPicker = false"
          />
        </van-popup>

      </demo-block>

      <demo-block title="" v-if="isNeedChannelApplicant">
        <van-field
            readonly
            clickable
            name="picker"
            :value="applicantName"
            label="公司信息"
            placeholder="点击选择公司信息"
            @click="channelApplicantShowPicker = true"
        />
        <van-popup v-model="channelApplicantShowPicker" position="bottom">
          <van-picker
              show-toolbar
              :columns="channelApplicant"
              value-key="applicantName"
              @confirm="onChannelApplicantConfirm"
              @cancel="channelApplicantShowPicker = false"
          />
        </van-popup>

      </demo-block>

      <!-- 通过 pattern 进行正则校验 -->
      <demo-block title="被保人信息">
        <van-cell v-for="item in insureFormData.insureInfoList" :key="item.id" size="large">
          <van-field
              v-model="item.insuredName"
              label="姓名"
              placeholder="被保人姓名"
              :rules="[{ required: true, message: '请输入正确被保人姓名' }]"
          />
          <!-- 通过 validator 进行函数校验 -->
          <van-field
              v-model="item.insuredCardNo"
              label="身份证"
              placeholder="被保人身份证"
              :rules="[{ required: true, message: '请输入正确的被保人身份证号码' }]"
          />
          <!-- 通过 validator 进行异步函数校验 -->
          <van-field
              v-model="item.insuredPhoneNo"
              label="手机号"
              placeholder="被保人手机号"
              :rules="[{ required: true, message: '请输入正确的被保人手机号' }]"
          />
        </van-cell>
<!--        <van-row type="flex" justify="end">-->
<!--          <van-col span="6">-->
<!--            <van-button native-type="button" icon="plus" type="primary" size="small" @click="insertInsured()" />-->
<!--          </van-col>-->
<!--        </van-row>-->
      </demo-block>

      <van-row style="margin: 16px; margin-top: 25px; margin-bottom: 30px">
        <van-checkbox v-model="isReadNotice">我已阅读并同意<samp v-html="readNoticeHtml"></samp></van-checkbox>
      </van-row>

      <!--      <van-field-->
      <!--          v-model="value1"-->
      <!--          name="pattern"-->
      <!--          placeholder="正则校验"-->
      <!--          :rules="[{ pattern, message: '请输入正确内容' }]"-->
      <!--      />-->
      <!--      &lt;!&ndash; 通过 validator 进行函数校验 &ndash;&gt;-->
      <!--      <van-field-->
      <!--          v-model="value2"-->
      <!--          name="validator"-->
      <!--          placeholder="函数校验"-->
      <!--          :rules="[{ validator, message: '请输入正确内容' }]"-->
      <!--      />-->
      <!--      &lt;!&ndash; 通过 validator 进行异步函数校验 &ndash;&gt;-->
      <!--      <van-field-->
      <!--          v-model="value3"-->
      <!--          name="asyncValidator"-->
      <!--          placeholder="异步函数校验"-->
      <!--          :rules="[{ validator: asyncValidator, message: '请输入正确内容' }]"-->
      <!--      />-->


      <div style="margin: 16px;">
        <van-button round block type="info" :disabled="!isReadNotice" native-type="submit" >提交</van-button>
      </div>
    </van-form>
    <van-dialog v-model="showWxPayQRTips" title="提示" show-cancel-button @confirm="checkPayInfo">
      <img :src="wxPayQRURL"/>
      <p>请用手机扫码或者长按二维码识别进行支付</p>
    </van-dialog>
  </div>
</template>

<script>
import {
  addInsure,
  unifyPay,
  payInfoByBizNo,
  getOauthPageUrl,
  getProductInfoByPlanCode,
  getDrivingSchool, getChannelApplicant
} from "@/api/insure";
import {Notify} from 'vant';
import DemoBlock from "@/components/common/DemoBlock";

export default {
  name: "InsureView",
  components: {DemoBlock},
  mounted() {
    this.onLoad();
    this.getProductInfo();
  },
  data() {
    return {
      loading: false,
      finished: false,
      showWxPayQRTips: false,
      wxPayQRURL: "",
      openId: null,
      isReadNotice: false,
      readNoticeHtml: '',
      insureFormData: {
        insureInfoList: [
          {
            id: '0',
            insuredName: "",
            insuredCardNo: "",
            insuredPhoneNo: "",
          }
        ],
        importWay: 2, //用户二维码导入
        applicantType: 1,//个人
        insuredTimePoint: null,
        planCode: "",
        channelCode: "",
        schoolCode: "",
      },
      product: {
        companyCode: null,
      },
      isShowInsuredTimePoint: false,
      isNeedDrivingSchool: false,
      drivingSchool: [],
      showPicker: false,
      schoolCode: '',
      schoolName: '',
      isNeedChannelApplicant: false,
      channelApplicant: [],
      channelApplicantShowPicker: false,
      applicantCode: '',
      applicantName: '',
      insuredBatchNo: null,
      companyCode: null,
      pattern: /\d{2}/,
    };
  },
  created () {
    if (typeof WeixinJSBridge == "undefined") {
      console.log("WeixinJSBridge is undefined")
    } else {
      this.getCode()
    }
  },
  methods: {
    getCode(){
      var openId = this.$route.query.openId;
      if (openId == null || openId === '') {
        var local = window.location.href;
        getOauthPageUrl({payType: 1, redirectPageBase64Url: btoa(local)}).then(res => {
          if (200 === res.code) {
            window.location.href = res.data;
          }else {
            Notify({type: 'warning', message: res.message});
          }
        }).catch(() => {
          Notify({type: 'warning', message: "错误====="});
        });
      }
    },
    getQueryVariable(variable) {
      var query = window.location.search.substring(1);
      var vars = query.split("&");
      for (var i=0;i<vars.length;i++) {
        var pair = vars[i].split("=");
        if(pair[0] == variable){return pair[1];}
      }
      return(false);
    },
    onLoad() {
      this.insureFormData.channelCode = this.$route.query.channelCode;
      this.insureFormData.planCode = this.$route.query.planCode;
      this.openId = this.$route.query.openId;
    },
    getProductInfo() {
      getProductInfoByPlanCode({planCode:this.insureFormData.planCode}).then(res => {
        if (200 === res.code) {
          var product = res.data;
          this.product = product;
          if (product && product.remark) {
            var remark = JSON.parse(product.remark);
            var insureNotice = remark.insureNotice;
            var  insureNoticeHtml = '';
            if (insureNotice) {
              for (var i in insureNotice) {
                var notice = insureNotice[i];
                insureNoticeHtml += '<a style="color: #0a8ddf" target="_blank" href="'+ notice.url + '">'+ notice.title +'</a>';
              }
              this.readNoticeHtml = insureNoticeHtml;
            }else {
              this.readNoticeHtml += '<a style="color: #0a8ddf">《保险条款》</a>';
            }
            this.isShowInsuredTimePoint = false;
            if (remark.isShowInsuredTimePoint) {
              this.isShowInsuredTimePoint = true;
            }

            this.isNeedDrivingSchool = false;
            //需要驾校信息
            if (remark.needDrivingSchool) {
              this.isNeedDrivingSchool = true;
             this.getDrivingSchool();
            }

            this.isNeedChannelApplicant = false;
            //需要驾校信息
            if (remark.needChannelApplicant) {
              this.isNeedChannelApplicant = true;
              this.getChannelApplicant();
            }

          }
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(()=> {
        Notify({type: 'warning', message: "产品信息获取失败"});
      });
    },

    getDrivingSchool() {
      getDrivingSchool({channelCode:this.insureFormData.channelCode}).then(res => {
        if (200 === res.code) {
          this.drivingSchool = res.data;
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(()=> {
        Notify({type: 'warning', message: "驾校信息获取失败"});
      });
    },

    getChannelApplicant() {
      getChannelApplicant({channelCode:this.insureFormData.channelCode}).then(res => {
        if (200 === res.code) {
          this.channelApplicant = res.data;
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(()=> {
        Notify({type: 'warning', message: "驾校信息获取失败"});
      });
    },

    onBridgeReady(params) {
      WeixinJSBridge.invoke( // eslint-disable-line
          'getBrandWCPayRequest',
          {
            appId: params.appId, // 公众号名称，由商户传入
            timeStamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
            package: params.packageValue, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: params.paySign // 支付签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 这里是查询订单是否支付完成，然后执行成功和失败的业务逻辑
              setTimeout(() => {
                this.checkPayInfo();
              }, 2000);
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              alert('支付失败！')
            }
          }
      )
    },
    insure() {
      if (this.insuredBatchNo && this.insuredBatchNo.length > 1) {
        this.insuredPay();
      } else {
        if (this.isShowInsuredTimePoint) {
          if (!this.insureFormData.insuredTimePoint) {
            Notify({type: 'warning', message: "请选择投保时间点"});
            return
          }
        }

        if (this.isNeedDrivingSchool) {
          if (!this.schoolCode) {
            Notify({type: 'warning', message: "请选择投保驾校信息"});
            return
          }
          this.insureFormData.schoolCode = this.schoolCode;
        }
        if (this.isNeedChannelApplicant) {
          if (!this.applicantCode) {
            Notify({type: 'warning', message: "请选择公司信息"});
            return
          }
          this.insureFormData.applicantCode = this.applicantCode;
          this.insureFormData.applicantType = 2;
        }
        this.loading = true;

        addInsure(this.insureFormData).then(res => {
          this.finished = true;
          this.loading = false;
          if (200 === res.code) {
            this.insuredBatchNo = res.data.batchCode;
            this.companyCode = res.data.companyCode
            this.insuredPay();
          } else {
            Notify({type: 'warning', message: res.message});
          }
        }).catch(() => {
          this.loading = false;
          Notify({type: 'warning', message: "投保失败！"});
          this.finished = true;

        });
      }
    },
    insuredPay() {
      this.loading = true;
      payInfoByBizNo({bizNo: this.insuredBatchNo}).then(res => {
        this.loading = false;
        if (200 === res.code) {
          var payInfoVo = res.data;
          if (0 === payInfoVo.payStatus
              || 3 === payInfoVo.payStatus) {
            if (this.companyCode == '1039') {
              var upPayInfo1 = {
                payType: 12,
                payNo: payInfoVo.payNo
              };
              //众安支付
              this.zhongAnPay(upPayInfo1);
              return;
            }

            if (this.companyCode == '9999') {
              // =============
              // adapay wx 支付
              // this.adapayWX(payInfoVo.payNo);
              // return;
            }

            if (this.companyCode == '1048') {
              // =============
              // 同行 wx 支付
              // var txWxpayInfo = {
              //   payType: 19,
              //   payNo: payInfoVo.payNo,
              //   openId: this.openId
              // };
              // this.wxJSAPIPay(txWxpayInfo);

              var acInfo = {
                payType: 20,
                payNo: payInfoVo.payNo
              };
              this.acPay(acInfo);
              return;
            }

            if (this.companyCode == '1040') {
              var upPayInfo2 = {
                payType: 16,
                payNo: payInfoVo.payNo
              };
              //星贝收银台支付
              this.xingbeiPay(upPayInfo2);
            } else {
              var upPayInfo = {
                payType: 2,
                payNo: payInfoVo.payNo
              };
              // if (typeof WeixinJSBridge == "undefined") {
              //   console.log("WeixinJSBridge isy undefined");
              //   upPayInfo.tradeType = 'NATIVE';
              //   this.wxQRPay(upPayInfo);
              // } else {
              //   // upPayInfo.openId = this.openId;
              //   // this.wxJSAPIPay(upPayInfo);
              //
              //   // this.adapayWX(payInfoVo.payNo);
              //   this.wxQRPay(upPayInfo);
              // }
              this.jsZfb(upPayInfo);
            }
          } else if (1 === payInfoVo.payStatus) {
            this.$router.push({path: '/insure/paySuccess'});
          } else {
            Notify({type: 'warning', message: "支付状态错误，请联系客服！"});
          }
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    wxQRPay(payInfo){
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          this.showWxPayQRTips = true;
          this.wxPayQRURL = "http://open.jisubx.com/m/getQrImageByBase64?content=" + btoa(res.data);
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    jsZfb(payInfo) {
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          const div=document.createElement('divform');
          div.innerHTML= res.data;
          document.body.appendChild(div);
          document.forms[0].acceptCharset='UTF-8';//保持与支付宝默认编码格式一致，如果不一致将会出现：调试错误，请回到请求来源地，重新发起请求，错误代码 invalid-signature 错误原因: 验签出错，建议检查签名字符串或签名私钥与应用公钥是否匹配
          document.getElementsByName('punchout_form')[0].submit();
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    zhongAnPay(payInfo){
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          this.showWxPayQRTips = true;
          this.wxPayQRURL = "http://open.jisubx.com/m/getQrImageByBase64?content=" + btoa(res.data);
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    xingbeiPay(payInfo){
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          this.showWxPayQRTips = true;
          this.wxPayQRURL = "http://open.jisubx.com/m/getQrImageByBase64?content=" + btoa(res.data);
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    wxJSAPIPay(payInfo) {
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          this.onBridgeReady(res.data);
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },

    acPay(payInfo) {
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          window.location.href = res.data;
        } else {
          Notify({type: 'warning', message: res.message});
        }
      });
    },
    checkPayInfo(){
      payInfoByBizNo({bizNo: this.insuredBatchNo}).then(res => {
        if (200 === res.code) {
          var payInfo = res.data;
          if (payInfo && 1 === payInfo.payStatus) {
            this.$router.push({path: '/insure/paySuccess'});
          } else {
            Notify({type: 'warning', message: "尚未收到支付结果信息，请稍等或者联系客服！"});
          }
        }else {
          Notify({type: 'warning', message: res.message});
        }
      }).catch(() => {
        Notify({type: 'warning', message: "支付信息查询失败！"});
        this.finished = true;
      });
    },
    adapayWX(payNo) {
      //微信公众支付
      var payInfo = {
        payType: 18,
        payNo: payNo,
        openId: this.openId
      };
      this.loading = true;
      unifyPay(payInfo).then(res => {
        if (200 === res.code) {
          this.onBridgeReady_adapay(res.data);
        } else {
          Notify({type: 'warning', message: res.message});
        }
      }).finally(()=>{
        this.loading = false;
      });
    },
    onBridgeReady_adapay(params) {
      WeixinJSBridge.invoke( // eslint-disable-line
          'getBrandWCPayRequest',
          {
            appId: params.appId, // 公众号名称，由商户传入
            timeStamp: params.timeStamp, // 支付签名时间戳，注意微信jssdk中的所有使用timestamp字段均为小写。但最新版的支付后台生成签名使用的timeStamp字段名需大写其中的S字符
            nonceStr: params.nonceStr, // 支付签名随机串，不长于 32 位
            package: params.package, // 统一支付接口返回的prepay_id参数值，提交格式如：prepay_id=\*\*\*）
            signType: params.signType, // 签名方式，默认为'SHA1'，使用新版支付需传入'MD5'
            paySign: params.paySign // 支付签名
          },
          function (res) {
            if (res.err_msg === 'get_brand_wcpay_request:ok') {
              // 这里是查询订单是否支付完成，然后执行成功和失败的业务逻辑
              setTimeout(() => {
                this.checkPayInfo();
              }, 2000);
            } else if (res.err_msg === 'get_brand_wcpay_request:fail') {
              alert('支付失败！')
            }
          }
      )
    },
    insertInsured() {
      this.insureFormData.insureInfoList.push({
        insuredName: "",
        insuredCardNo: "",
        insuredPhoneNo: ""
      });
    },
    readNotice(){
     this.isReadNotice = !this.isReadNotice;
    },
    onConfirm(value) {
      this.schoolCode = value.schoolCode;
      this.schoolName = value.schoolName;
      this.showPicker = false;
    },

    onChannelApplicantConfirm(value) {
      this.applicantCode = value.applicantCode;
      this.applicantName = value.applicantName;
      this.channelApplicantShowPicker = false;
    },
  },
}
</script>
